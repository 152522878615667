import './registerServiceWorker';
import Vue from 'vue';
import VueRouter from 'vue-router';

import auth from '@websanova/vue-auth/src/v2.js';
import driverAuthBearer from '@websanova/vue-auth/src/drivers/auth/bearer.js';
import driverHttpAxios from '@websanova/vue-auth/src/drivers/http/axios.1.x.js';
import driverRouterVueRouter from '@websanova/vue-auth/src/drivers/router/vue-router.2.x.js';

import './plugins';
import './plugins/global-components';

import axios from 'axios';
import VueAxios from 'vue-axios';

import store from './plugins/store';
import App from './App.vue';

import './scss/style.scss';

import { routes } from './router/index';
import i18n from './i18n'

Vue.use(VueAxios, axios);
Vue.use(VueRouter);

window.axios = axios;
window.moment = require('moment');

// Set the base URL for Axios
// axios.defaults.baseURL = 'https://api.hulk333.vip/api/';
axios.defaults.baseURL = 'https://hulk.salmon.vip/api/';
// axios.defaults.baseURL = 'http://127.0.0.1:8000/api/';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const authToken = localStorage.getItem('token');
if (authToken) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
}

// Set up the VueRouter instance
const router = new VueRouter({
  mode: 'history',
  routes,
  // linkExactActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }; // Scroll to the top on every route change
  }
});

// Before each route, persist the `referrer` query if it exists
router.beforeEach((to, from, next) => {
  // Check if the `referrer` query exists in the current route and not in the next one
  if (from.query.referrer && !to.query.referrer) {
    next({
      path: to.path,
      query: { ...to.query, referrer: from.query.referrer }, // Append `referrer` query
      replace: true // Avoid adding extra history entries
    });
  } else {
    next(); // Proceed without changes if the query is already there
  }
});

// Use the VueAuth plugin
Vue.use(auth, {
  plugins: {
    http: Vue.axios,
    router,
  },
  drivers: {
    auth: driverAuthBearer,
    http: driverHttpAxios,
    router: driverRouterVueRouter,
  },
  options: {
    loginData: {
      url: 'auth/login',
      method: 'POST',
    },
    registerData: {
      url: 'auth/register',
      method: 'POST',
    },
    fetchData: {
      url: 'auth/user',
      method: 'GET',
    },
    authRedirect: {
      path: '/auth/login',
    },
  },
});

Vue.config.productionTip = false;

// Initialize the Vue instance
const app = new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: (h) => h(App)
});
